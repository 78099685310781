import moment from "moment"
import { firestore as db } from "src/Firebase"

export const getUnidades = (idConjunto) =>
  db
    .collection("conjuntos")
    .doc(idConjunto)
    .collection("unidadesHabitacionales")
    .get()

export const getConjuntoComprobante = (observer, Conjunto) =>
  db.collection("conjuntos").doc(Conjunto).onSnapshot(observer)

export const getUserByConjunto = (observer, conjuntoID) =>
  db
    .collection("usuarios")
    .where("ConjuntoUidResidencia", "==", conjuntoID)
    .where("Roles.residente", "==", true)
    .onSnapshot(observer)

export const updatePagoPendiente = (idConjunto, pagoId, data) =>
  db
    .collection("conjuntos")
    .doc(idConjunto)
    .collection("cuentasPorCobrar")
    .doc(pagoId)
    .update({
      Nombre: data.Nombre,
      Descripcion: data.Descripcion,
      FechaLimite: new Date(data.FechaLimite),
      Valor: data.Valor,
      Rubro: data.Rubro,
      SubRubro: data.SubRubro,
    })

export const deletePagoPendiente = (idconjunto, pagoId) =>
  db
    .collection("conjuntos")
    .doc(idconjunto)
    .collection("cuentasPorCobrar")
    .doc(pagoId)
    .delete()

export const newPagoPendiente = (conjuntoId, data) =>
  db
    .collection("conjuntos")
    .doc(conjuntoId)
    .collection("cuentasPorCobrar")
    .add({
      Nombre: data.Nombre,
      Valor: data.Valor * 1,
      FechaLimite: new Date(data.FechaLimite),
      FechaRegistro: new Date(),
      Descripcion: data.Descripcion,
      UsuarioId: data.UsuarioId,
      NombreUsuario: data.NombreUsuario,
      CasaUsuario: data.CasaUsuario,
      Rubro: data.Rubro,
      SubRubro: data.SubRubro,
      ValorOriginal: data.Valor * 1,
    })

export const updateUltimaExpensaConjunto = (idConjunto, UltimoMesAlicuota) =>
  db.collection("conjuntos").doc(idConjunto).update({
    UltimoMesAlicuota,
  })

// FIXIT: Buscar al usuario por nombre, apellido, cedula. Ya no por correo
export const getUser = (userName, userLast, userDNI) =>
  db
    .collection("usuarios")
    .where("Cedula", "==", userDNI)
    .where("Nombre", "==", userName)
    .where("Apellido", "==", userLast)
    .get()

// TODO: Obtener las unidades habitacionales vacias
export const getUnidadesHab = (conjuntoID, observer) =>
  db
    .collection("conjuntos")
    .doc(conjuntoID)
    .collection("unidadesHabitacionales")
    .onSnapshot(observer)

export const suscriberReceivables = (conjuntoUid, userId, casaId, observer) =>
  db
    .collection("conjuntos")
    .doc(conjuntoUid)
    .collection("cuentasPorCobrar")
    .where("UsuarioId", "==", userId)
    .where("CasaUsuario", "==", casaId)
    .orderBy("FechaLimite")
    .onSnapshot(observer)

export const getUsuariosMorososSinCasa = (conjuntoUid, userId, observer) =>
  db
    .collection("conjuntos")
    .doc(conjuntoUid)
    .collection("cuentasPorCobrar")
    .where("UsuarioId", "==", userId)
    .orderBy("FechaLimite")
    .onSnapshot(observer)

export const getPendingPays = (conjuntoUid, user) =>
  db
    .collection("conjuntos")
    .doc(conjuntoUid)
    .collection("cuentasPorCobrar")
    .where("UsuarioId", "==", user)
    .where("FechaLimite", "<=", moment().endOf("month").toDate())
    .get()

export const getAllPendingPays = (conjuntoUid) =>
  db
    .collection("conjuntos")
    .doc(conjuntoUid)
    .collection("cuentasPorCobrar")
    .where("FechaLimite", "<=", moment().endOf("month").toDate())
    .get()

export const getPendingPaysMonth = (conjuntoUid, user, desde, hasta) =>
  db
    .collection("conjuntos")
    .doc(conjuntoUid)
    .collection("cuentasPorCobrar")
    .where("UsuarioId", "==", user)
    .where("FechaLimite", ">=", desde)
    .where("FechaLimite", "<=", hasta)
    .get()

export const getRubros = (conjuntoUid, tipo) =>
  db
    .collection("conjuntos")
    .doc(conjuntoUid)
    .collection("rubros")
    .where("Tipo", "==", tipo)
    .get()

export const getSubRubros = (conjuntoUid, Rubroid) =>
  db
    .collection("conjuntos")
    .doc(conjuntoUid)
    .collection("rubros")
    .doc(Rubroid)
    .get()
export const getSubconjuntos = (idConjunto, observer) =>
  db
    .collection("conjuntos")
    .doc(idConjunto)
    .collection("conjuntoConfig")
    .doc("Subconjuntos")
    .onSnapshot(observer)

export const updateUsuario = (idUser, data) =>
  db.collection("usuarios").doc(idUser).update({
    Familia: data,
  })

export const updateCorreo = (idUser, data) =>
  db.collection("usuarios").doc(idUser).update({
    CorreosNotificacion: data,
  })

export const updateUnidadHabitacional = (conjuntoUid, unidad, data) =>
  db
    .collection("conjuntos")
    .doc(conjuntoUid)
    .collection("unidadesHabitacionales")
    .doc(unidad)
    .update({
      FechaUltimoPago: new Date(data),
    })

export const updateUser = (userid, data) =>
  db.collection("usuarios").doc(userid).update({
    Roles: data,
  })

export const getModulos = (observer, idConjunto) =>
  db
    .collection("conjuntos")
    .doc(idConjunto)
    .collection("conjuntoConfig")
    .doc("Modulos")
    .onSnapshot(observer)

export const updateUH = async (conjuntoUid, unidadId, data) => {
  try {
    await db
      .collection("conjuntos")
      .doc(conjuntoUid)
      .collection("unidadesHabitacionales")
      .doc(unidadId)
      .update(data);
  } catch (error) {
    throw new Error("No se pudo actualizar la unidad habitacional");
  }
};
    